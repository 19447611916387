<template>
  <smart-widget :isActived="false" :simple="editable" class="smart-widget" :class="{editable: editable}">

    <v-scale-transition>
      <v-card class="card-widget fill-height overflow-y-scroll "
              ref="widget"
              :class="[{
                'card-scaledown' : hover && editable
              }]"
              :style="movingStyle"
              @mouseenter="hover = true" @mouseleave="hover = false">
        <vue-scroll class=" pr-1">
          <div class="d-flex align-center justify-center movableHovered" :class="{moving: moving}"
                  v-if="editable && hover">

              <v-icon x-large>mdi-cursor-move</v-icon>
            </div>

          <component :moving="moving" :is="widget" @update="update" @add="add" @remove="remove"></component>
        </vue-scroll>

      </v-card>
    </v-scale-transition>
  </smart-widget>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "Widget",
  props: {
    editable: Boolean,
    pathToWidget: String,
    componentName: String
  },
  computed: {
    widget: function () {
      if (this.componentName != undefined) {
        const componentName = this.kebabToPascal(this.componentName)
        return () => import(`@/Components/Views/Home/Widgets/Widget${componentName}.vue`)
      }
      return null
    }
  },
  methods: {
    update: async function (val) {
      this.$store.commit("updateWidgetDatas", val)
      await this.saveStore()
      this.cheatKey = this.$uuid.v4()
    },
    add: async function (val) {
      this.$store.commit("addWidgetDatas", val)
      await this.saveStore()
      this.cheatKey = this.$uuid.v4()
    },
    remove: async function (val) {
      this.$store.commit("deleteWidgetDatas", val)
      await this.saveStore()
      this.cheatKey = this.$uuid.v4()
    },
    saveStore: async function () {
      let store = this.$_.pick(this.$store.state, ["widgetBoard"])
      await PlaneteOnlineService.postInterface(store)
    }
  },
  data() {
    return {
      moving: false,
      hover: false,
      movingStyle: {},
      cheatKey: null
    }
  }
}
</script>

<style>
.smart-widget {
  position: relative;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.card-widget {
  transition: all .15s ease-in-out;
}

.movableHovered {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, .2);
  cursor: grab;
  margin-bottom: 2px;
}
.card-scaledown {
  transform: scale(.98);
}

.moving {
  cursor: grabbing;
}

.smart-widget + .vue-resizable-handle {
    margin-bottom: 2px;
}

.smart-widget:not(.editable) + .vue-resizable-handle {
  height: 0 !important;
  padding: 0 !important;
}

</style>
